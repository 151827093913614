var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dashhead"},[_c('div',{staticClass:"dashhead-titles"},[_c('h6',{staticClass:"dashhead-subtitle"},[_vm._v(_vm._s(_vm.subTitle))]),_c('h3',{staticClass:"dashhead-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])]),_c('hr',{staticClass:"my-3"}),_c('sgv-form',{attrs:{"method":_vm.method,"options":_vm.options},on:{"update:method":function($event){_vm.method=$event}}},[_c('div',[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ชื่อ","validations":[
            {text: 'required!', value: _vm.$v.formData.name.$dirty && _vm.$v.formData.name.$error}
          ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ประเภท","placeholder":"Ex. cpar","validations":[
            {text: 'required!', value: _vm.$v.formData.paperTemplates.$dirty && _vm.$v.formData.paperTemplates.$error}
          ]},model:{value:(_vm.formData.paperTemplates),callback:function ($$v) {_vm.$set(_vm.formData, "paperTemplates", $$v)},expression:"formData.paperTemplates"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"แผนก","placeholder":"Ex. hr","validations":[
            {text: 'required!', value: _vm.$v.formData.paperTypes.$dirty && _vm.$v.formData.paperTypes.$error}
          ]},model:{value:(_vm.formData.paperTypes),callback:function ($$v) {_vm.$set(_vm.formData, "paperTypes", $$v)},expression:"formData.paperTypes"}}),_c('sgv-input-number',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"จำนวนรายการ","validations":[
            {text: 'required!', value: _vm.$v.formData.paperLimit.$dirty && _vm.$v.formData.paperLimit.$error}
          ]},model:{value:(_vm.formData.paperLimit),callback:function ($$v) {_vm.$set(_vm.formData, "paperLimit", $$v)},expression:"formData.paperLimit"}})],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ใช้งาน","inline":""},model:{value:(_vm.formData.isActive),callback:function ($$v) {_vm.$set(_vm.formData, "isActive", $$v)},expression:"formData.isActive"}})],1)])])]),(_vm.kioskViewId > 0)?_c('div',[_vm._v(" URL: "+_vm._s(_vm.apiLink)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }